.App-logo {
  width: 219px !important;
  height: 36px !important;
}

.Carrega-button:hover {
  border-color: white !important;
}

.App-header {
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
  font-size: 12px;
  background-color: #5d5760;
  color: white;
}

.Header-branding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
}

.Header-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  margin-right: 0px;
}

.Carrega-button:hover {
  border-color: white !important;
}

.App-searchImage {
  text-align: center;
}

.startSearch {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: rgba(66, 66, 66, 1);
  margin-top: 5%;
  margin-bottom: 5px;
}

.findSamples {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  margin-bottom: 20px;
}

.buttonRestaurar {
  width: 45% !important;
  background: #ffffff !important;
  box-shadow: 0px 7px 8px rgb(255, 255, 255) !important;
  border-radius: 4px !important;
  margin-right: 50px !important;
  color: #900000 !important;
  font-weight: bold !important;
}




.espacio {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.enLinea {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.enLineaConEspacio {

  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  padding-left: 15%;
  margin: 2%;
}

.botonesRestaurarCercar {
  display: flex;
  flex-direction: row;
  align-content: center;
  box-sizing: border-box;
}

.column {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.end {
  margin-left: auto;
  margin-right: 2;
  margin-top: 17px !important;
}

.flex-items {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.center {
  height: 100vh;
  /* Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
}

.pruebaaa {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.boxCenter {
  margin-left: 50;
  margin-right: 500;
}